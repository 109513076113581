import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StaticImageUrlPipe } from './static-image-url.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StaticImageUrlPipe],
  exports: [StaticImageUrlPipe]
})
export class UtilStaticImageUrlModule {}
