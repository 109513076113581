import { Inject, Pipe, PipeTransform } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';

@Pipe({
    name: 'staticImageUrl',
    standalone: false
})
export class StaticImageUrlPipe implements PipeTransform {
  constructor(@Inject(APP_CONFIG) private appConfig) {}

  transform(name: string, folder = '', extension?, type: 'normal' | 'shared' = 'normal'): string {
    if (extension) {
      return this.getStaticImageUrl(this.getExtension(name, extension), folder, type);
    }
    return this.getStaticImageUrl(name, folder, type);
  }

  getStaticImageUrl(name, folder?, type?: 'normal' | 'shared') {
    switch (type) {
      case 'normal': {
        return folder !== '' ? `${this.appConfig.imgServerUrlImages}/${folder}/${name}` : `${this.appConfig.imgServerUrlImages}/${name}`;
      }
      case 'shared': {
        return folder !== ''
          ? `${this.appConfig.imgServerUrlSharedImages}/${folder}/${name}`
          : `${this.appConfig.imgServerUrlSharedImages}/${name}`;
      }
    }
  }

  getExtension(img: string, extension: string) {
    const splitImage = img.split('.');
    const tempExtension = splitImage[splitImage.length - 1];
    return img?.replace(tempExtension, extension);
  }
}
